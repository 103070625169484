export const bookNodes = [
        {
            id: 0,
            name: "הכושר ההוליסטי",
            starRating: 5,
            remarks: ["hi"],
            languages: ["Hebrew", "English", "French"],
            cover: "./client-images/",
            shortDesc: "הכושר ההוליסטי מציג בפני הקורא גישה חדשנית וייחודית לפיתוח כושר גופני, השמה דגש על צרכיו השונים של המתאמן, לבד מאלו הפיזיים: צרכים רגשיים, רוחניים, שכליים, חברתיים ועוד. זאת לאור ההנחה כי לא די לשלוט בכל מרכיבי הכושר, בעקרונות האימון ובשיטותיו, אלא חשוב להכיר את מגוון הגורמים הפנימיים להנעה ולהתמדה בפעילות גופנית...",
            description: "",
            color: '#65b3e155',
            direction: "rtl",
            coverSrc: require("../assets/client-images/holistic-fitness.webp"),
            fullCover: require("../assets/client-images/Holistic-fitness-book-full-cover.png"),
            purchaseUrl: "https://www.mendele.co.il/product_author/%D7%93%D7%A8-%D7%99%D7%92%D7%90%D7%9C-%D7%A4%D7%A0%D7%97%D7%A1/"
        },
        {
            id: 1,
            name: "המדריך השלם לפעילות בחדר הכושר",
            starRating: 5,
            remarks: ["hi"],
            languages: ["Hebrew", "English", "French"],
            cover: "./client-images/",
            shortDesc: "הספר נבחר על ידי מחלקת כושר קרבי בצה\"ל כספר הראוי ביותר לשימוש קציני האימון גופני בחילות צה\"ל השונים. הספר נבחר ע\"י מחלקת הספורט של ע.ת.א כספר הראוי ביותר לשימוש רכזי הספורט. הספר נבחר כספר שי למצטיינים בחינוך במכללת סמינר הקיבוצים ועוד.",
            description: "",
            color: '#65b3e155',
            direction: "rtl",
            coverSrc: require("../assets/client-images/complete-guide.png"),
            fullCover: require("../assets/client-images/complete-guide-full-cover.jpg"),
            purchaseUrl: ""
        },
        {
            id: 2,
            name: "BE YOUR OWN PERSONAL TRAINER",
            starRating: 5,
            remarks: ["hi"],
            languages: ["Hebrew", "English", "French"],
            cover: "./client-images/",
            shortDesc: "Backed by years of experience and scientific evidence, expert fitness trainer and veteran physical educator Yigal Pinchas, Ph.D., presents the most \n comprehensive and detailed guide to training in the gym available to date. Recognized by the IDF as the leading training and instruction manual for \n soldiers, \"Be Your Own Personal Trainer\" has received recommendations from leading fitness organizations in the world since its initial publication.",
            description: "",
            direction: "ltr",
            color: '#65b3e155',
            coverSrc: require("../assets/client-images/personal-trainer.webp"),
            fullCover: require("../assets/client-images/Personal-trainer-full-cover.png"),
            purchaseUrl: "https://www.goodreads.com/book/show/160857448-be-your-own-personal-trainer"
        },
        {
            id: 3,
            name: "The Complete Holistic Guide To Working Out In The Gym",
            starRating: 5,
            remarks: ["hi"],
            languages: ["Hebrew", "English", "French"],
            cover: "./client-images/",
            shortDesc: "Training in the gym is one of the most common sports in the Western world. Thanks to the \nenormous scientific development in the industry, the gym stands out as the most effective means of \n managing the body. The gym provides a wide range of physical, health, mental and social needs and \n is suitable for diverse target populations.",
            description: "",
            direction: "ltr",
            color: '#65b3e155',
            coverSrc: require("../assets/client-images/complete-holistic-guide.png"),
            fullCover: require("../assets/client-images/complete-holistic-guide.png"),
            purchaseUrl: ""
        },
        {
            id: 4,
            name: "ממתיחות לגמישות - המדריך השלם",
            starRating: 5,
            remarks: ["hi"],
            languages: ["Hebrew", "English", "French"],
            cover: "./client-images/",
            shortDesc: "הספר הנוכחי מתמקד בדרכים לשיפור הגמישות הפיזית מתוך הנחה, שזו רק שלב התחלתי למימוש כלל ההיבטים של הגמישות; שריר גמיש הוא שריר סתגלן, כשם שניתן להגדיל את היקף התנועה כך ניתן להרחיב את גבולות החשיבה והדימיון.",
            description: "",
            direction: "rtl",
            color: '#65b3e155',
            coverSrc: require("../assets/client-images/stretching.webp"),
            fullCover: require("../assets/client-images/stretching-full-cover.jpg"),
            purchaseUrl: "https://www.mendele.co.il/product_author/%D7%93%D7%A8-%D7%99%D7%92%D7%90%D7%9C-%D7%A4%D7%A0%D7%97%D7%A1/"
        }
    ]

export const bookContent = [
    {
        id: 0,
        subHeading: "כושר הוליסטי® – רקע ומבוא",
        paragraphs: [
                "לאחר עשרות שנים של עיסוק בחינוך גופני ובהוראת חינוך גופני בקרב אלפי מתאמנים ותלמידים במערכות הפורמליות והבלתי פורמליות, הבנתי כי כדי לקצור הצלחות דרושה גישה שונה מאלה הרווחות כיום בתחום. במהלך הוראת הפעילות הגופנית אם בחדרי הכושר, אם בחוגים, אם בבתי־ספר, בתיכונים ובמכללות – נוכחתי לדעת כי הגישות הקיימות אינן נותנות תשובה מספקת למגוון האוכלוסיות. עובדה ידועה היא, שלמרות יתרונותיו הגלויים והמובהקים של האימון הגופני, הרי שמרבית בני האדם עדיין נמנעים מפעילות כושר, ואלה הנוטלים בה חלק מתקשים לממש ולקיים פעילותם בהתמדה.",
                "עם השנים פיתחתי גישה חדשה, אשר נועדה לתת מענה לכלל המתאמנים, ובמיוחד לאותם אלפים, שעד עתה נשרו מתוכניות כושר, לא התמידו בהן או נמנעו מהן לגמרי. גישה זו, על כוחותיה ועל חוזקותיה, נוסדה במיוחד כדי לענות על צרכי המתאמן בשלל היבטים: פיזיים,רגשיים, רוחניים, שכליים.",
                "גישת הכושר ההוליסטי® מיוסדת על הפילוסופיה ההוליסטית, לפיה האדם הוא מכלול שלם, מערכת 'אורגנית' אחת שרכיביה גוף, נפש, רוח ושכל. כאשר רכיב מסוים משתנה, או שמשתנה תפקודו, אזי גם יתר הרכיבים במערכת יושפעו וישתנו בהתאם, שכן בין חלקי המכלול קיים יחס של גומלין וקשר הדדי מובהק. בהינתן שכך, הרי כדי לחולל שינוי פיזי בקרב מתאמן, עלינו להתבונן בכל פניו, כולל הנפשיים, ההתנהגותיים, החברתיים וכד'. עוד עלינו לזהות את מערכות היחסים בין שלל הפנים השונים הקשורים זה בזה ומשפיעים זה על זה.",
                "כאשר אנו עוסקים במונח 'כושר' כוונתנו ליכולת, כלומר לכשירות לבצע פעולה. שם העצם הזכרי כֹּשֶׁר שאול מתכונתו של הַכָּשֵׁר, זה שמצבו טוב. בעולם ההלכה הכַשרוּת היא תַקִּינות. המילה כוללת גם משמעות של יכולת ואפשרות, כגון בצירופים: כושר עבודה, כושר ניתוח. כך אפוא כושר גופני.",
                "מכאן ש'הכושר הגופני' במובנו הרחב אינו אלא אחד ממכלול הכשרים של האדם לצד כשרים שכליים, רגשיים, חברתיים ועוד. בהתייחס לכך 'כושר גופני' הוא זה המאפשר לאדם להפעיל את כלל מערכות גופו, בכל טווח הפעילות התפקודית, לשם הבטחת קיומו וסיפוק צרכיו. במסגרת הגדרה זו נכללות מערכות הגוף השונות: עצבים, נשימה, לב וכלי־דם, תנועה, עיכול, שתן, מין והמערכת החיסונית. אלא שהצירוף 'כושר גופני' מתייחס רק למערכת ה תנועה בדרגת מאמץ משמעותי.",
                "בעוד 'הכושר הגופני' מתייחס לגוף לבדו, ובתוך כך למערכת התנועה לבדה בדרגת מאמץ משמעותי, הרי שגישת 'הכושר ההוליסטי' תתייחס לכל אחד מן הממדים באדם: גופני, נפשי, רוחני וקוגניטיבי בִּכלל דרגות המאמץ. זוהי אפוא תפישה הדוגלת בטיפוח אורח חיים בריא ופעיל המבוססת על פעילות גופנית השואפת להרמוניה כוללת עם סך הרבדים שבאדם.",
                "תפישה זו מוצאת ביטויה כבר אצל המשורר הרומי יובנאליס בשנת 100 לספירה, כמובעת בהיגד השגור, 'נֶׁפֶׁש ברִּיאָּה בגוּף בָּרִּיא'; והרחי בזאת הרב יצחק הכהן קוק בספרו 'אורות התשובה' ובמקורות נוספים: \"נשמה בריאה בגוף בריא מוכרחת היא לבוא לידי האושר הגדול של תשובה\" ('אורות התשובה', פרק ה, א). הגם שמוגשת קדימות תחבירית לבריאות הנפש או הנשמה כתלויה בבריאות הגוף, אין עוררין בדבר יחסי הגומלין והתלות בין המרכיבים. אף הרמב\"ם (המאה ה־ 12), שהיה כידוע גם רופא, אחז בתפישה הוליסטית הרואה בגוף ובנפש מהות אחת. מיוחס לו ביאור הערך 'בריאות' על־דרך האקרוסטיכון: \"בולם רוגזו יפחית אוכלו ויגביר תנועתו\" (הרמב\"ם מעלה נושא זה בספרו 'הלכות דעות', פרק ד).",
                "מובן כי מתוך התבוננות רחבה עלינו לקחת בחשבון את יתר המרכיבים המשפיעים על האדם, ומאפשרים לו להגיע לרמת כושר משמעותית ואיכותית, כגון: תזונה, שינה ועוד. הגם שלא מעניינו של ספר זה לתארם, הרי שאין להקל ראש בחשיבותם, כחלק מהתורה ההוליסטית.",
                "תפישת הכושר ההוליסטי מציעה אפוא להתבונן באפשרות להניע אדם לפעילות גופנית דרך מגוון כוחות, לא רק דרך הספקטרום הפיזי. כוחות אלה מכילים בתוכם מספר מועצם של רכיבי השפעה עבור כל אחד מן האספקטים של חיינו, כאמור החברתי, הרגשי, השכלי, הסביבתי ועוד.",
                "שכלול הגישה ההוליסטית הביאני לכדי פיתוח 'ארגז כוחות' שהם כ'כלי הפעלה' העומדים לרשות המאמן והמתאמן גם יחד, בהתאם לאישיותו ולנטיותיו של כל אחד ואחד מהם. כוחות אלה נמצאו בעיני כיעילים וכאפקטיביים ביותר, מעבר לכלֵׁי האימון השגורים, כמתווה לאורח חיים פעיל ובריא, המיוסד על המהות ההוליסטית. הם מהווים אמצעי הנעה לפעילות, אמצעי לשיפור מרכיבי הכושר בעת הפעילות, ואמצעי יעיל להתמדה ממושכת בה מתוך שלמות והנאה.",
                "ספר זה נועד לשרת קהילת מאַמנים, כמובן מתאמנים, מורים לחינוך גופני, חוקרים בתחום הספורט, מורים, וכל העוסקים בתחום הלכה ומעשה. תקוותי כי ימשוך קהילות רבות יותר אל התחום המאתגר של עולם הכושר ויסייע לפעול בו באופן מושכל ומהנה, כמשחק מענג."
        ]
    },
    {
        id: 1,
        subHeading: "מהדורה 3 ומורחבת, 2019",
        paragraphs: [
                "הספר היחידי שתורגם מחבר ישראלי לאנגלית בתחום של החינוך הגופני ומשמש כ\"טקסטבוק\" בצפון אמריקה (הגרסה הישנה שתורגמה לאנגלית נמכרת ב- 45$, הגרסה העברית השלישית והמורחבת מכילה תוספת של כ-60 עמודים ע\"י עם תוספות של איורים ועדכונים)",
                "הספר תורגם ופותח ל - DVD באמצעות University of Calgary שבקנדה: The Complete Multimedia Guide to Working Out in the Gym",
                "כאשר אנו עוסקים במונח 'כושר' כוונתנו ליכולת, כלומר לכשירות לבצע פעולה. שם העצם הזכרי כֹּשֶׁר שאול מתכונתו של הַכָּשֵׁר, זה שמצבו טוב. בעולם ההלכה הכַשרוּת היא תַקִּינות. המילה כוללת גם משמעות של יכולת ואפשרות, כגון בצירופים: כושר עבודה, כושר ניתוח. כך אפוא כושר גופני.",
                "הספר מכיל קונספציה ורציונל בכל פרק ומאפשר חשיבה רפלקטיבית במהלך ואחרי הפעילות. הספר מחנך לפעילות גופנית מאוזנת ומושכלת וכתוב בראייה הוליסטית.",
                "בספר כ- 300 איורים המדויקים ביותר בשחור לבן בשווי 25,000$",
                "הספר היינו משנה סדורה בתחום הפעילות הגופנית. תורת הכושר הגופני, מושגי יסוד, שיטות אימון לפיתוח כוח, סבולת וגמישות, ומשמש כספר לימוד בקורסי מאמנים: אתלטיקה, אירובי, חדרי כושר ועוד",
                "בספר פרקים מעשיים ייחודיים המיטיבים להמחיש את אופן הביצוע של תרגילי הכוח והאירובי השונים. (הרציונל לביצוע התרגילים מוסבר באופן שווה לכל קורא), פרקים הסוקרים באופן רפלקטיבי את המכשור לפעילות אירובית ופעילות אנאירובית, ופרק בנושא תזונה המאפשר הכרה והבנה לכל מרכיבי אבות המזון, טיפים בנושא תזונה לפעילות אירובית ותזונה לפעילות אנאירובית והנחיות לתזונה נבונה",
                "בסוף ספר פרק בנושא איך לבנות תוכניות אימון אישיות (ילדים, מבוגרים, צרכים ייחודיים), המאפשר באמצעות מתן תשובות לשאלונים ייחודיים (גרסה ייחודית ע\"ס מחקרים וניסיון) לזהות את ההבדל בין רצון לצורך ואיך להפוך את הצרכים למטרות",
                "הספר נבחר על ידי מחלקת כושר קרבי בצה\"ל כספר הראוי ביותר לשימוש קציני האימון גופני בחילות צה\"ל השונים. הספר נבחר ע\"י מחלקת הספורט של ע.ת.א כספר הראוי ביותר לשימוש רכזי הספורט. הספר נבחר כספר שי למצטיינים בחינוך במכללת סמינר הקיבוצים ועוד",
               
        ],
        Link: [
            {
                src:"http://www.amazon.com/Complete-Holistic-Guide-Working-Out/dp/155238215X",
                altText: "לינק לרכישה"
            }]
    },
    {
        id: 2,
        subHeading: "Everything you need to achieve maximum gains is in the palm of your hand",
        paragraphs: [
                {
                    heading: "DESCRIPTION",
                    content: "Backed by years of experience and scientific evidence, expert fitness trainer and veteran physical educator Yigal Pinchas, Ph.D., presents the most comprehensive and detailed guide to training in the gym available to date. Recognized by the IDF as the leading training and instruction manual for soldiers, \"Be Your Own Personal Trainer\" has received recommendations from leading fitness organizations in the world since its initial publication",
                },
                {
                    heading: "Everything you need to achieve maximum gains is in the palm of your hand",
                    content: "People exercise for a variety of different reasons. Whether you are looking to lose weight, tone your muscles, improve your mobility, or gain an edge on the field, your individual goals should determine your training program, not the other way around. A well-informed training regimen and comprehensive holistic approach is the best way to achieve these goals while minimizing the risk of injury or failure. And you can make one yourself",
                },
                {
                    heading: "FROM THE AUTHOR",
                    content: "This book was born out of necessity, specifically, the need to create order and uniformity in fitness terminology that will allow trainers and trainees to have fruitful, more effective sessions. I did my best to present a unilateral interpretation of the common terms and practices in the field, with as wide a context as possible. All the while giving readers a deep and meaningful understanding of how they interact with one another, and how to practice both effectively and safely.",
                },
                "I put in \"Be Your Own Personal\" Trainer several unique chapters, including one detailing the importance of flexibility not only in sports performance but in our daily lives in general. Nutrition, muscle development, stamina, and goals management all take part in this theoretical and practical guide",
                "Whether you are looking to lose weight, tone your muscles, improve your mobility, or gain an edge on the field, your individual goals should determine your training program, not the other way around. A well-informed training regimen and comprehensive holistic approach is the best way to achieve these goals while minimizing the risk of injury or failure. And you can make one yourself",
                "The complete guide to Working out in the gym with a holistic approach is intended for exercisers in general and sports teachers and fitness trainers in particular. The book is written in a structured way, which allows the reader to progress step by step, starting from his first steps in the gym to the stages of a professional and independent exerciser",
                "",
                "Revised and optimized with the latest research in physical gym training, expert fitness trainer and veteran physical educator Yigal Pinchas, Ph.D., presents the most comprehensive and detailed guide to training in the gym available to date. this edition includes:",
        ],
        points: [
            "100+ specially designed exercises that target different muscle groups and cover various aspects of training, including stretches, cardio, aerobics, and effectively using multi-purpose machines",
            "A scientifically supported personally tailored training program designed specifically for you",
            "Over 300 fully illustrated original drawings that provide clear and precise instructions for optimal range of movement and technique",
            "Comprehensive instructions for building strength and stamina, improving flexibility, promoting muscle relaxation, and maintaining a balanced training and diet",
            "The “Five by Five” model: how integrated fitness components work, and their contribution to the development of sports skills",
            "New proven methods for achieving training commitments and goals while minimizing the risk of injuries"
        ]
    },
    {
        id: 3,
        paragraphs: [
                "Training in the gym is one of the most common sports in the Western world. Thanks to the enormous scientific development in the industry, the gym stands out as the most effective means of managing the body. The gym provides a wide range of physical, health, mental and social needs and is suitable for diverse target populations",
                "In this book, the most up-to-date research aspects on the subject are concentrated, starting from the basic concepts, through the description of the execution of the exercises, to the construction of a personal training plan and guidelines for a sensible diet. The book contains about 200 unique illustrations that help describe and illustrate how to perform the exercises in a visual way that makes the book a useful guide",
                "The complete guide to Working out in the gym with a holistic approach is intended for exercisers in general and sports teachers and fitness trainers in particular. The book is written in a structured way, which allows the reader to progress step by step, starting from his first steps in the gym to the stages of a professional and independent exerciser",
                "",
                "Through this book you will learn:",
        ],
        points: [
            "The way of performing the exercises in a visual way",
            "The advanced training methods for developing the various fitness components",
            "The pros and cons of the gym",
            "How to increase awareness and motivation for physical activity",
            "How to organize your body and train intelligently",
            "A variety of exercises for all muscle groups in the body",
            "How to realize the training goals without getting injured",
            "How to build a personal training plan for each trainee according to his needs",
            "The nutritional components required for the various types of activity",
            "A balanced approach to training and eating habits",
            "Reflective and balanced thinking during and after the activit"
        ]
    },
    {
        id: 4,
        paragraphs: [
            {
                heading: "פתח דבר",
                content: "מרכיב הגמישות ניתן לפיתוח בכל גיל והוא איננו מוגבל למין או לאוכלוסייה מסוימת, כל אחד יכול לשפר את מרכיב הגמישות בהיבטיו השונים:",
            },
            "- בהיבט המחשבתי מציין את מידת היכולת להשתנות", 
            "- בהיבט החברתי מציין את מידת היכולת לקבל/להכיל את הזר והשונה",
            "- בהיבט הריגשי מציין את מידת היכולת להתמודד עם דחיית סיפוקים והתמודדות עם תחושות של אי נוחות",
            "לסיכום ניתן לומר שהגמישות בכללותה היא זו שמאפשרת לנו להסתגל לחיים, להתמודד עם אתגרי החיים החל מרמת האי נוחות/סבל ועד להגשמת הסיפוק המירבי.",
            {
                heading: "הספר הנוכחי מתמקד בדרכים לשיפור הגמישות הפיזית מתוך הנחה, שזו רק שלב התחלתי למימוש כלל ההיבטים של הגמישות; שריר גמיש הוא שריר סתגלן, כשם שניתן להגדיל את היקף התנועה כך ניתן להרחיב את גבולות החשיבה והדימיון",
                content: " "
            }
        ]
    }
]